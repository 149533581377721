var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        class: _vm.table,
        staticStyle: { cursor: "pointer" },
        attrs: {
          dense: "",
          "sort-by": "ad_start_date",
          "sort-desc": "",
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.adPlanners,
          "items-per-page": _vm.pageSize,
          "item-key": "adId",
          color: "#fff",
          "fixed-header": "",
          "loading-text": "Loading... Please Wait",
          "no-results-text": "No Matching Ad Planners Found",
          "no-data-text": _vm.selectedStore
            ? "No Ad Planners Found"
            : "Please Select Store to View Ad Planners",
          "hide-default-footer": "",
        },
        on: {
          "update:items": function ($event) {
            _vm.adPlanners = $event
          },
          "click:row": _vm.viewAdPlanner,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { cursor: "default" },
                      attrs: { flat: "", color: "toolbar", height: "75" },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Ad Planners")]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { vertical: "", inset: "" },
                      }),
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "4", lg: "3" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "mt-7",
                                class: _vm.body,
                                attrs: {
                                  "auto-select-first": "",
                                  items: _vm.userAdGroups,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                  "hide-no-data": "",
                                  "background-color": "input",
                                  label: "Ad Group",
                                  outlined: "",
                                  dense: "",
                                  "prepend-inner-icon": "mdi-filter-outline",
                                  "menu-props": { maxHeight: 260 },
                                  rules: [
                                    function (v) {
                                      return !!v || "Required"
                                    },
                                  ],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                },
                                                [_vm._v(" Ad Group ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedAdGroup,
                                  callback: function ($$v) {
                                    _vm.selectedAdGroup = $$v
                                  },
                                  expression: "selectedAdGroup",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "mt-7",
                                class: _vm.body,
                                attrs: {
                                  "auto-select-first": "",
                                  items: _vm.storeParties,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                  "background-color": "input",
                                  placeholder: "Select Store",
                                  label: "Store",
                                  outlined: "",
                                  dense: "",
                                  "prepend-inner-icon": "mdi-filter-outline",
                                  "menu-props": { maxHeight: 260, rounded: "" },
                                  disabled: !_vm.selectedAdGroup,
                                  rules: [
                                    function (v) {
                                      return !!v || "Required"
                                    },
                                  ],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "text-capitalize",
                                                },
                                                [_vm._v(" Store ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedStore,
                                  callback: function ($$v) {
                                    _vm.selectedStore = $$v
                                  },
                                  expression: "selectedStore",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.ad_details",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "open-on-hover": "",
                        "offset-y": "",
                        bottom: "",
                        "close-on-content-click": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-information")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v("Ad ID"),
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.ad_id)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v("Created On"),
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDateTimestamp(
                                              item.created_on
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v("Created By"),
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.created_by)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.ordering_status",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-4",
                      class: _vm.statusDisplay(item.ad_start_date, "color"),
                      attrs: { small: "", dark: "" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            margin: "0 auto",
                            width: "120px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.statusDisplay(item.ad_start_date, "text")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "item.last_shipper_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatDateDisplay(
                          _vm.orderingDates(item.ad_start_date, "last_shipper")
                        )
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.last_order_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatDateDisplay(
                          _vm.orderingDates(item.ad_start_date, "last_order")
                        )
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.ad_start_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatDateDisplay(item.ad_start_date)) +
                      " - " +
                      _vm._s(_vm.formatDateDisplay(item.ad_end_date)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.audit.updated_on",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatDateTimestamp(item.audit.updated_on)) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-8",
                                attrs: {
                                  dense: "",
                                  items: _vm.pageSizes,
                                  label: "Items Per Page",
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500,
                                  },
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disablePrevious,
                              },
                              on: { click: _vm.previous },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disableNext,
                              },
                              on: { click: _vm.next },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }